import { Environment, useHelper } from "@react-three/drei";
import { folder, useControls } from "leva";
import React, { useRef } from "react";
import { DirectionalLightHelper } from "three";
const lde = {
  dirl_position: { x: 4.0, y: 5.0, z: 0 },
};

function Lights() {
  const dirLight = useRef();

  return (
    <>
      {/* <Environment files="/public/images/goegap_1k.hdr" background /> */}

      {/* <directionalLight
        ref={dirLight}
        position={[
          lde.dirl_position.x,
          lde.dirl_position.y,
          lde.dirl_position.z,
        ]}
        intensity={.5}
      /> */}
    </>
  );
}

export default Lights;
