import React, { useState } from "react";
import { copy_clip, dec, getv, nils } from "../utils/utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faBiohazard,
  faCircle,
  faClose,
  faEarth,
  faF,
  faFemale,
  faFire,
  faFlag,
  faHammer,
  faMale,
  faMars,
  faMinus,
  faTeletype,
  faTriangleExclamation,
  faTrophy,
  faVenus,
  faWater,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { Card, Tag } from "./utilityComps.js";
import { MoVariants } from "../utils/motion_helper.js";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";

export const cbsk = [10, 12, 14, 16, 18, 20, 22];

export const BImg = ({ hex_code, className }) => {
  return (
    <>
      <div
        style={{ background: `#${hex_code}` }}
        className={twMerge(
          "xs:w-[1rem] xs:h-[1rem] lg:w-[4rem] lg:h-[4rem] m-1 rounded-md",
          className
        )}
      ></div>
    </>
  );
};

const MiniStatCard = ({ icon, head, txt, icn, cn, wrapcn }) => {
  return (
    <div className={twMerge("resp-p-1 rounded-md", wrapcn)}>
      <div className="fr-sc gap-2">
        <div className={twMerge("resp-px-2 resp-text--1", icn)}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="flex flex-col justify-center items-start">
          <div className="font-mon resp-text--2 text-slate-300">{head}</div>
          <div
            className={twMerge(
              "font-mon resp-text--2 text-white font-bold",
              cn
            )}
          >
            {txt}
          </div>
        </div>
      </div>
    </div>
  );
};

export const BikeCard = ({ bike: b, close = null }) => {
  const [expand, set_expand] = useState(true);
  return (
    <Card className={"max-w-[98vw] w-[45rem] py-[3px] bg-dark bg-opacity-80 text-white"}>
      <div
        onClick={() => copy_clip(b.hid)}
        className="flex flex-row justify-start items-start resp-gap-4 cursor-pointer"
      >
        <BImg hex_code={b.hex_code} />
        <div className="flex flex-col flex-1 justify-start items-start">
          <div className="fr-sc resp-gap-1 w-full">
            <div className="flex-1"></div>
            <div className="font-digi resp-text-1">{b.name}</div>
            <div className="flex-1"></div>
            <span className="text-acc0 font-sebold resp-text--1">
              {b.vault_name}
            </span>
            {!nils(close) && (
              <Tag
                onClick={close}
                className={twMerge("tet-red-400 resp-px-2 text-black")}
              >
                <FontAwesomeIcon icon={faClose} />
              </Tag>
            )}
          </div>
          <div
            // variants={MoVariants.show_hide}
            // initial="hidden"
            // animate={expand ? "visible" : "hidden"}
            className="w-full"
          >
            <div className="grid grid-cols-4 resp-gap-2 resp-my-2">
              {[
                ["Type", _.capitalize(b.type), faTeletype],

                b.gender == "male"
                  ? [
                      "Gender",
                      _.capitalize(b.gender),
                      faVenus,
                      "text-blue-400",
                      "text-blue-400",
                    ]
                  : [
                      "Gender",
                      _.capitalize(b.gender),
                      faVenus,
                      "text-pink-400",
                      "text-pink-400",
                    ],

                ["F.No", `F${b.fno}`, faF],
                [
                  "Element",
                  _.capitalize(b.element),
                  ...((b.element == "earth" && [
                    faEarth,
                    "text-orange-900",
                    "text-orange-900",
                  ]) ||
                    (b.element == "water" && [
                      faWater,
                      "text-blue-400",
                      "text-blue-400",
                    ]) ||
                    (b.element == "fire" && [
                      faFire,
                      "text-orange-400",
                      "text-orange-400",
                    ]) ||
                    (b.element == "metal" && [
                      faHammer,
                      "text-slate-900",
                      "text-slate-400",
                    ]) ||
                    []),
                ],
                ["Races", b.races_n ?? 0, faFlag],
                ["Win %", `${dec(b.win_p ?? 0, 1)}%`, faTrophy],
                [
                  "Color",
                  _.chain(b.color)
                    .split("-")
                    .map(_.capitalize)
                    .join(" ")
                    .value(),
                  faCircle,
                  `text-${b.color}`,
                  "",
                ],
              ].map(([head, txt, icon, icn, cn]) => {
                return (
                  <MiniStatCard
                    {...{
                      icon,
                      head,
                      txt,
                      icn,
                      cn,
                      wrapcn: head == "Color" ? "col-span-2" : "",
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
