import React, { useMemo, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { useTexture } from "@react-three/drei";
import { Texture } from "three";
import { FloatType } from "three";

const textureSize = 64.0;
function getRandom(min, max) {
  return Math.random() * (max - min) + min;
}
const drawRadialGradation = (ctx, canvasRadius, canvasW, canvasH) => {
  ctx.save();
  const gradient = ctx.createRadialGradient(
    canvasRadius,
    canvasRadius,
    0,
    canvasRadius,
    canvasRadius,
    canvasRadius
  );
  gradient.addColorStop(0, "rgba(255,255,255,1.0)");
  gradient.addColorStop(0.5, "rgba(255,255,255,0.5)");
  gradient.addColorStop(1, "rgba(255,255,255,0)");
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvasW, canvasH);
  ctx.restore();
};

const getTexture = () => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const diameter = textureSize;
  canvas.width = diameter;
  canvas.height = diameter;
  const canvasRadius = diameter / 4;

  drawRadialGradation(ctx, canvasRadius, canvas.width, canvas.height);

  const texture = new Texture(canvas);
  texture.type = FloatType;
  texture.needsUpdate = true;
  return texture;
};

const circularTexture = getTexture();

const Snowflake = ({ position, size, tex }) => {
  const snowflakeRef = useRef();
  const [opacity, setOpacity] = useState(0.4);
  const [start, setStart] = useState(true);
  const { speedX, speedY } = useMemo(() => {
    return { speedX: getRandom(-2, 5) || 1, speedY: getRandom(5, 10) || 1 };
  }, []);
  useFrame(({ clock }) => {
    if (snowflakeRef.current) {
      if (start) {
        setOpacity((prevOpacity) => Math.min(prevOpacity + 0.05, 10));
        snowflakeRef.current.position.x = position[0] || 0;
        snowflakeRef.current.position.y = position[1] || 0;
        snowflakeRef.current.position.z = position[2] || 0;
        setStart(false);
      } else {
        const et = 20;
        // snowflakeRef.current.position.x = 50 * Math.sin((Math.PI * clock.elapsedTime) / 100) + position[0] + 0.5 * Math.sin(snowflakeRef.current.position.y)
        snowflakeRef.current.position.y -= (et * 1) / 20;
        // snowflakeRef.current.position.z = position[2] || 0
        if (snowflakeRef.current.position.y < -1) {
          // setStart(true);
          snowflakeRef.current.position.x = position[0] || 0;
          snowflakeRef.current.position.y = 30 || 0;
          snowflakeRef.current.position.z = position[2] || 0;
          setOpacity(0.4);
        }
      }
    }
  });
  //   console.log(tex)
  if (snowflakeRef?.current?.position?.y < -1) return;

  return (
    <mesh
      ref={snowflakeRef}
      scale={size / 10}
      rotation={[
        snowflakeRef.current?.position?.z,
        snowflakeRef.current?.position?.y || position[0],
        snowflakeRef.current?.position?.x || position[1],
      ]}
    >
      <planeGeometry />
      <meshPhysicalMaterial
        side={2}
        color={0xffffff}
        vertexColors={false}
        transparent
        map={tex}
        alphaMap={tex}
        depthWrite={false}
      />
    </mesh>
  );
};

const Snowfall = ({ snowfall }) => {
  const snowList = useMemo(() => {
    const list = [];
    for (let i = 0; i < snowfall; i++) {
      list.push({
        position: [
          getRandom(-50 * 3, 100 * 3),
          Math.random() * 30,
          getRandom(-20, 20),
        ],
        size: getRandom(1, 15),
      });
    }
    return list;
  }, [snowfall]);
  // const tex = useTexture("/images/star.png");
  // const tex1 = useTexture("/images/PngItem_1702088.png");
  // const tex2 = useTexture("/images/snow1.PNG");

  const textures = [circularTexture];
  return (
    <>
      <group position={[-80, 0, 0]}>
        {snowList.map(({ position, size }, index) => (
          <Snowflake
            position={position}
            key={index}
            size={size}
            tex={textures[index % textures.length]}
          />
        ))}
      </group>
    </>
  );
};
export default Snowfall;
