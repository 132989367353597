export const trackpiece_per100m = 4;
export const sx = 4;
export const metrics = {
  bike: {
    scale: 1,
    between: 0.1,
    width: 0.5,
  },
  text_emissive_intensity: 0.5,
  sx,
};
