import { useLoader } from "@react-three/fiber";
import {
  EffectComposer,
  SSR,
  Bloom,
  LUT,
  DepthOfField,
  Glitch,
} from "@react-three/postprocessing";

export function Effects({ applyglitch }) {
  return (
    <EffectComposer>
      {applyglitch && <Glitch strength={applyglitch} />}
    </EffectComposer>
  );
}
