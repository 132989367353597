import { useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const Delay = ({ delay = 500, ...props }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (show) return <>{props.children}</>;
  return null;

  return <>{show && <></>}</>;
};

export const Tag = (props) => {
  let pointer = props.onClick || props.redirect ? "cursor-pointer" : "";
  return (
    <a
      {...(props.redirect
        ? {
            href: props.redirect,
            target: props.target ?? "_blank",
          }
        : {})}
      {...{
        className: props.acn ?? "",
      }}
    >
      <div
        {...props}
        onClick={() => {
          if (props.onClick) props.onClick();
        }}
        style={{ ...props.style }}
        className={twMerge(
          "px-[4px] py-[2px] font-bold",
          "mx-1 my-0.5 font-bold",
          `nowrap rounded-md`,
          pointer,
          props?.active === false ? "bg-opacity-25" : "",
          props.className
        )}
      >
        {props.children}
      </div>
    </a>
  );
};

export const BY_Star = ({ star, className }) => {
  const [bluestar, yellowstar] = useMemo(() => {
    if (star == 5) return [1, 1];
    else if (star == 3) return [0, 1];
    else if (star == 2) return [1, 0];
    else return [0, 0];
  }, [star]);

  // console.log({ star, bluestar, yellowstar });

  return (
    <div className="flex flex-row justify-center items-center gap-1 px-2 my-1">
      {bluestar == 1 && (
        <FontAwesomeIcon
          icon={faStar}
          className={twMerge("text-blue-400", className)}
        />
      )}
      {yellowstar == 1 && (
        <FontAwesomeIcon
          icon={faStar}
          className={twMerge("text-yellow-400", className)}
        />
      )}
    </div>
  );
};

export const Card = ({ className, ...props }) => {
  return (
    <div
      className={twMerge("rounded-md p-4 mb-2 bg-reg w-max mx-auto", className)}
    >
      {props.children}
    </div>
  );
};

export const ContactTag = ({ icon, txt, link }) => {
  return (
    <Link to={link} target="_blank">
      <div className="resp-p-2 fr-sc resp-gap-2 resp-text--1 text-black">
        <Tag className="fc-cc text-[1.5rem] xs:w-[1.5rem] xs:h-[1.5rem] md:w-[2rem] md:h-[2rem] lg:w-[4rem] lg:h-[4rem] rounded-full bg-dark shadow-md shadow-acc0 text-white">
          <FontAwesomeIcon className="resp-text-1" icon={icon} />
        </Tag>
        <span className="font-mon italic px-2 text-black">{txt}</span>
      </div>
    </Link>
  );
};
